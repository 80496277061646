<template>
  <div
    class="d-flex align-center flex-column"
    style="background-color:papayawhip;"
  >
    <v-row>
      <v-col cols="12" md="10">
        <v-toolbar
          flat
          class="mt-3 mx-auto"
          min-width="350"
          width="95%"
          :id="bookie_data[0].ReBtHostID"
        >
          <v-spacer></v-spacer>
          <v-btn rounded outlined color="purple" @click="SortClick()">
            <v-icon left>
              mdi-filter-variant
            </v-icon>
            {{ getSortText() }}
          </v-btn>
        </v-toolbar>

        <v-card
          class="mx-auto mt-2 mb-2"
          min-width="350"
          width="95%"
          v-for="(bookie, bookie_index) in bookie_data"
          :key="bookie_index"
          :id="bookie_data[bookie_index].ReBtHostID"
        >
          <v-card-title style="background-color:whitesmoke;color: black;;">
            <AddData
              :ReBtHostID="bookie.ReBtHostID"
              :ReBtHostName="bookie.Name"
              :Sort="Sort"
            ></AddData>
            {{ bookie.Name }}
          </v-card-title>
          <v-card-text>
            <v-row
              v-for="(bettype, index) in bookie.BtType"
              :key="index"
              class="mb-0 mt-0 pb-0 pt-0"
            >
              <v-col>
                <v-toolbar color="teal lighten-5" dense full-width>
                  <v-toolbar-title>
                    <div class="font-weight-bold">
                      {{
                        getBetTypeText(
                          bettype.BetTypeDetailID,
                          bettype.Permute,
                          bettype.isExtraType,
                          bettype.Percent,
                          bettype.ExtraTypePercent
                        )
                      }}
                    </div>
                    <div class="purple--text ml-4">
                      {{
                        getPrizeText(
                          bettype.BetTypeDetailID,
                          bettype.Permute,
                          bettype.isExtraType,
                          bettype.Percent,
                          bettype.ExtraTypePercent
                        )
                      }}
                    </div>
                  </v-toolbar-title>
                </v-toolbar>

                <v-card-text class="mt-0 pt-0 pl-1">
                  <v-chip
                    class="ma-2"
                    :color="bettype.Percent == 0 ? 'red' : 'teal darken-1'"
                    text-color="white"
                    v-for="(hp_number, index_number) in bettype.num"
                    :key="index_number"
                  >
                    {{ hp_number }}
                  </v-chip>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- <v-list-item link @click="deleteItem()">
          <v-list-item-icon>
            <v-icon color="red">mdi-delete-forever</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ลบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      <!-- @click="$vuetify.goTo('#'+bookie.ReBtHostID,options)" -->
      <!-- @click="bookie_link_click(bookie.ReBtHostID)" -->
      <v-col cols="0" md="2" class="hidden-sm-and-down">
        <v-layout class="mt-3 mr-5 fixed" width="100%">
          <v-navigation-drawer>
            <v-list density="compact" nav>
              <v-list-item
                v-for="(bookie, bookie_index) in bookie_data"
                :key="bookie_index"
                dense
                link
                :href="'#' + bookie.ReBtHostID"
                ><v-icon icon="fa:fas fa-list"></v-icon>
                <v-list-item-icon>
                  <v-icon color="blue">mdi-account-circle-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ bookie.Name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
          <v-main style="height: 250px"></v-main>
        </v-layout>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import PopupMenu from "@/components/Cus/Line/Genaral/Popup_Menu";
import AddData from "@/components/Data/Export/Bookie/HPByBookie/Add_Data";

export default {
  name: "HPByBookie_Datatable",

  components: {
    // PopupMenu,
    AddData,
  },
  created() {
    //โหลด ตาราง
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    bookie_data() {
      return this.$store.getters["bookie/HpBookies"];
    },
    bookie_count() {
      return this.$store.getters["bookie/HpBookies"].length;
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        ListType: "hp_by_bookie",
        Sort: this.Sort,
      };
      await this.$store.dispatch("bookie/get_hp_bookie", credentials).then(
        (response) => {
          //console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    SortClick() {
      this.Sort = (this.Sort + 1) % 2;
      this.fetch_table();
    },
    getBetTypeText(bet_type, Permute, isExtraType) {
      var bet_type_text = "";
      switch (parseInt(bet_type)) {
        case 1: //3 ตัวบน
          bet_type_text = "3 ตัวบน";
          if (isExtraType == 1) bet_type_text += " โต๊ด";
          if (Permute == 1) bet_type_text += " * 6 หาง";
          if (isExtraType == 0) bet_type_text += " (ไม่รวมโต๊ด)";
          break;
        case 2:
          bet_type_text = "3 ตัวโต๊ด";
          break;
        case 3:
          bet_type_text = "2 ตัว บน";
          if (isExtraType == 1) bet_type_text += " + ล่าง";
          if (Permute == 1) bet_type_text += " พร้อมตัวกลับ";
          if (Permute == 0) bet_type_text += " (ไม่รวมตัวกลับ)";
          break;
        case 4:
          bet_type_text = "2 ตัว ล่าง";
          if (isExtraType == 1) bet_type_text += " + บน";
          if (Permute == 1) bet_type_text += " พร้อมตัวกลับ";
          if (Permute == 0) bet_type_text += " (ไม่รวมตัวกลับ)";
          break;
      }
      return bet_type_text;
    },
    getPrizeText(bet_type, Permute, isExtraType, Percent, ExtraTypePercent) {
      var prize_text = "";
      switch (parseInt(bet_type)) {
        case 1: //3 ตัวบน
          if (Percent == 50) prize_text = " จ่ายครึ่ง";
          if (Percent < 0) {
            //จ่ายบาทละ
            if (isExtraType == 0) {
              prize_text = "จ่ายตรง " + Percent * -1;
            } else {
              prize_text =
                "จ่ายตรง " + Percent * -1 + " โต๊ด " + ExtraTypePercent * -1;
            }
          }
          if (Percent == 0) prize_text = "ปิดรับ";
          return prize_text;
        case 2:
          if (Percent == 0) prize_text = "ปิดรับ";
          if (Percent == 50) prize_text = " จ่ายครึ่ง";
          if (Percent < 0) prize_text = " จ่ายบาทละ " + ExtraTypePercent * -1;
          return prize_text;
        case 3:
          if (Percent == 0) prize_text = "ปิดรับ";
          if (Percent == 50) prize_text = " จ่ายครึ่ง";
          if (Percent < 0) prize_text = " จ่ายบาทละ " + ExtraTypePercent * -1;
          return prize_text;
        case 4:
          if (Percent == 0) prize_text = "ปิดรับ";
          if (Percent == 50) prize_text = " จ่ายครึ่ง";
          if (Percent < 0) prize_text = " จ่ายบาทละ " + ExtraTypePercent * -1;
          return prize_text;
      }
    },
    getSortText() {
      if (this.Sort == 0) return "เรียงตามเวลา";
      return "เรียงตามเลข";
    },
  },
  data: () => ({
    selectedId: -1,
    Sort: 0, //by time
  }),
};
</script>

<style scoped>
.fixed {
  position: fixed;
}
::v-deep .id_column_class {
  background-color: #026921 !important;
  color: white;
  /* text-align: center !important;  
    padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 3%;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: #026921 !important;
  color: white !important;
  padding-top: 15px !important;
}

::v-deep table thead th:last-child {
  padding-left: 40px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
  font-size: 18px !important;
}

::v-deep table {
  background-color: #fafafa;
}

::v-deep table tbody tr td {
  font-size: 1em !important;
  color: rgb(88, 88, 88);
  font-weight: normal;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 1em !important;
    color: rgb(88, 88, 88);
    font-weight: bold;
  }
}
</style>

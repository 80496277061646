<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :items="export_decide_data"
      :items-per-page="10"
      item-key="id"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="h-screen"
      fixed-header
      dense
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat>
          <h4 class="text-h5 font-weight-bold orange--text text--darken-4">
            {{ getBetTypeText(BetType) }}
          </h4>
          <v-divider class="mx-4 info" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <SelectBookie ref="SelectBookie"  @UpdateHost="fetch_table()" />
        </v-toolbar>
        <!-- <v-alert dense text class="text-center" type="info">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ profit_count }}</strong> รายการ
        </v-alert> -->
      </template>
      <template slot="header">
        <thead>
          <tr>
            <th colspan="3" class="h_id text-center" style="width: 8%;">ตัดออก</th>
            <th
              colspan="5"
              class="h_num text-center grey lighten-2 white-space: normal"
              style="width: 15%;"
            >
              กำรไขาดทุนสูงสุด (คำนวนทุกเบอร์)
            </th>
            <th
              class="h_status text-center grey lighten-2"
              colspan="5"
              style="width: 18%;"
            >
              กำไรขาดทุนตามระดับ
            </th>
            <th
              class="h_betsum text-center grey lighten-4"
              colspan="2"
              style="width: 18%;"
            ></th>
          </tr>
          <tr>
            <th colspan="1" class="h_id" style="width: 8%;">
              (เบอร์)
            </th>
            <th
              colspan="1"
              class="h_num text-center grey lighten-2 white-space: normal"
              style="width: 10%;"
            >
              ตัดที่
            </th>
            <th
              class="h_betprice text-center grey lighten-4 white-space: normal"
              colspan="1"
              style="width: 10%;"
            >
              ยอกส่งออก
            </th>
            <th
              class="h_status text-center grey lighten-4"
              colspan="1"
              style="width: 18%;"
            >
              กำไร
            </th>
            <th
              class="h_status text-center grey lighten-4"
              colspan="1"
              style="width: 3%;"
            >
              เลข
            </th>
            <th
              class="h_betsum text-center grey lighten-4"
              colspan="1"
              style="width: 18%;"
            >
              ขาดทุน
            </th>
            <th
              class="h_status text-center grey lighten-4"
              colspan="1"
              style="width: 3%;"
            >
              เลข
            </th>
            <th
              class="h_pricesum text-center grey lighten-2"
              :colspan="1"
              style="width: 5%;"
            >
              อัตราส่วน
            </th>
            <th
              class="h_pricesum text-center grey lighten-4"
              :colspan="1"
              style="width: 15%;"
            >
              5
            </th>
            <th
              class="h_pricesum text-center grey lighten-2"
              :colspan="1"
              style="width: 15%;"
            >
              4
            </th>
            <th
              class="h_pricesum text-center grey lighten-4"
              :colspan="1"
              style="width: 15%;"
            >
              3
            </th>
            <th
              class="h_pricesum text-center grey lighten-2"
              :colspan="1"
              style="width: 15%;"
            >
              2
            </th>
            <th
              class="h_pricesum text-center grey lighten-4"
              :colspan="1"
              style="width: 15%;"
            >
              1
            </th>
            <th
              class="h_winsum text-center grey lighten-2"
              :colspan="1"
              style="width: 5%;"
            >
              เบอร์กำไร/ขาดทุน
            </th>
            <th
              class="h_bettime text-center grey lighten-4"
              :colspan="1"
              style="width: 15%;"
            >
              โอกาส
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="{ item }">
        <!-- @contextmenu="popup_menu($event, item)" -->
        <tr
          @touchstart="touch = true"
          @click="row_click($event, item)"
          @contextmenu="popup_menu($event, item)"
          :class="{ 'pink lighten-5': item.COL === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <!-- <td :class="[id_column, getIDColor(item.id)]"> -->
          <td :class="id_column">
            {{ item.CNC }}
          </td>
          <td align="end">
            {{ item.CON }}
          </td>
          <td align="end">
            {{
              NUM2STR(item.E_PRI)
            }}
          </td>
          <td align="center" :class="GetLossClass(item.MG,item.ML)">
            {{
              NUM2STR(item.MG)
            }}
          </td>
          <td>{{ item.NUMMG }}</td>
          <td align="center" :class="GetLossClass(item.MG,item.ML)">
            {{
              NUM2STR(item.ML)
            }}
          </td>
          <td>{{ item.NUMML }}</td>
          <td align="center">{{(Math.abs(item.ML)/ item.MG).toFixed(2)}}</td>
          <td align="end">{{ NUM2STR(item.D5) }}</td>
          <td align="end">{{ NUM2STR(item.D4) }}</td>
          <td align="end">{{ NUM2STR(item.D3) }}</td>
          <td align="end">{{ NUM2STR(item.D2) }}</td>
          <td align="end">{{ NUM2STR(item.D1) }}</td>
          <td align="center" :class="PercentClass((item.NGC / (item.NGC+item.NLC))*100)">{{ item.NGC + "/" +  item.NLC}}</td>
          <td align="center" :class="PercentClass((item.NGC / (item.NGC+item.NLC))*100)">{{ ((item.NGC / (item.NGC+item.NLC))*100).toFixed(2) + "%" }}</td>
        </tr>
      </template>
      <template v-slot:footer>
          <v-container class="max-width">
            <v-pagination
              v-model="Page"
              :length="getPageCount()"
              @input="fetch_table()"
            ></v-pagination>
          </v-container>

      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" />
  </div>
</template>

<script>
import PopupMenu from "@/components/Data/Export/Export_Decide/Genaral/Popup_Menu";
import SelectBookie from "@/components/Data/Export/Export_Decide/Genaral/SelectBookie";

export default {
  name: "Export_Decide_Datatable",

  components: {
    PopupMenu,
    SelectBookie,
  },
  created() {
    this.BetType = this.$route.query.bettype;
    //โหลด ตาราง
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    export_decide_data() {
      return this.$store.getters["export_decide/ExportDecides"];
    },
    export_decide_count() {
      return 0; //this.$store.getters["bookie/Bookies"].length;
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BetType: this.BetType,
        Page: this.Page,
        // ListType: "profit_loss_list",
      };
      await this.$store
        .dispatch("export_decide/get_export_decide_list", credentials)
        .then(
          (response) => {
            //console.log(response);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    getBetTypeText(betType) {
      const statusText = [
        "รวมบน",
        "3 ตัวบน",
        "3 ตัวโต๊ด",
        "2 ตัวบน",
        "2 ตัวล่าง",
        "คู่โต๊ด",
        "",
        "พวง",
        "",
        "จม บ.น.",
        "จม บ.ก.",
        "จม บ.ท.",
        "จม. ล.น.",
        "จม. ล.ท.",
        "ลอยบน",
        "ลอยล่าง",
        "คู่หน้า",
        "คู่ถ่าง",
      ];
      if (betType == -1) return "รวมล่าง";
      if (betType == 0) return "รวมบน";
      return statusText[parseInt(betType)];
    },
    getIDColor(betType) {
      const statusText = [
        "",
        "indigo",
        "indigo",
        "indigo",
        "teal",
        "indigo",
        "",
        "indigo",
        "",
        "indigo",
        "indigo",
        "indigo",
        "teal",
        "teal",
        "indigo",
        "teal",
        "indigo",
        "indigo",
      ];
      if (betType == -1) return "pink";
      if (betType == 0) return "pink";
      return statusText[parseInt(betType)];
    },
    getPageCount() {
      const PageCount = [
        100,
        100,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
        10,
      ];
      return PageCount[parseInt(this.BetType)];
    },
    getStatusText(status) {
      const statusText = ["ปกติ", "ระงับ"];
      return statusText[parseInt(status)];
    },
    getStatusColor(status) {
      const statusColor = ["green--text", "red--text"];
      return statusColor[parseInt(status)] + " pl-10";
    },
    getNamesColor(status) {
      const statusColor = ["", "red--text"];
      return statusColor[parseInt(status)];
    },
    PercentClass(Percent){
        let P =parseInt(Percent);
        if(P>=98) return "green--text text--accent-4";
        else if(P>=80) return "green--text text--darken-4";
        else if(P>=75) return "blue--text text--accent-4";
        else if(P>=65) return "orange--text text--darken-4";
        else return "red--text";
    },
    GetLossClass(MG,ML){
        if(MG > (ML*-1)) return "green--text text--accent-4";
        else if(((ML*-1)-MG)>MG) return "green--text text--darken-4";
        else if(((ML*-1)-MG)>MG/3) return "orange--text text--accent-4";
        else return "blue--text text--darken-4";
    },
    row_click: function(e, item) {
      if (this.selectedId == item.COL) {
        if (this.touch == true) this.popup_menu(e, item);
      }

      this.selectedId = item.COL;
      this.$set(item, "selected", true);
    },
    popup_menu: function(e, item) {
      e.preventDefault();
      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.Show(this.BetType,item.COL);
        this.selectedId = item.COL;
        //console.log(item);
      });
    },
    NUM2STR(Num) {
      if (Num != "") {
        Num = Num.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      }
      return Num;
    },
  },
  data: () => ({
    selectedId: -1,
    BetType: 0,
    touch: false,
    Page: 1,
    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,
    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  color: white;
  text-align: right !important;
  padding-top: 3px !important;
  /* position:absolute;  */
  border-color: rgb(245, 242, 242) !important;
  background: rgb(127, 101, 143) !important;
  position: sticky !important;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: rgb(250, 242, 255) !important;
  color: rgb(43, 42, 42) !important;
  padding-top: 0px !important;
}

::v-deep table thead th:last-child {
  padding-left: 40px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
  font-size: 16px !important;
}

::v-deep table {
  background-color: #fafafa;
}

::v-deep table tbody tr td {
  font-size: 1em !important;
  color: rgb(0, 0, 0);
  font-weight: normal;
  border-right: 1px solid;
  border-color: lightgray;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 0.9em !important;
    color: rgb(88, 88, 88);
    font-weight: bold;
  }
}
</style>

<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :hide-overlay="$vuetify.breakpoint.mdAndDown"
    persistent
    max-width="505px"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mr-4"
        v-bind="attrs"
        v-on="on"
        rounded
        outlined
        color="purple"
        small
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>เพิ่มเลขอั้น - {{ ReBtHostName }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div class="text-center">
          <v-snackbar
            v-model="snackbar.show"
            :timeout="snackbar.timeout"
            :color="snackbar.color"
            absolute
            top
            center
          >
            {{ snackbar.text }}
          </v-snackbar>
        </div>
        <v-row>
          <v-col cols="12" class="pt-5">
            <v-select
              :items="type_items"
              item-text="text"
              item-value="value"
              v-model="selectedType"
              @change="changedValue"
              dense
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pt-0">
            <v-select
              :items="prize_item"
              item-text="text"
              item-value="value"
              v-model="selectedPrize"
              dense
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="selectedPrize == 2">
          <v-col cols="6" class="pt-0">
            <v-text-field
              label="จ่ายตรง"
              type="number"
              v-model="Prize1"
              ref="txtPrize1"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pt-0" v-if="selectedType == 3">
            <v-text-field
              label="จ่ายโต๊ด"
              type="number"
              v-model="Prize2"
              ref="txtPrize2"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8" class="pt-0">
            <v-text-field
              label="เลข"
              type="number"
              v-model="BetNumber"
              ref="txtBetNumber"
              v-on:keyup.enter="OnAddBetType()"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="pt-0 text-right">
            <v-btn color="" @click="OnAddBetType()">
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-for="(bettype, index) in bettype_items"
          :key="index"
          class="mb-0 mt-0 pb-0 pt-0"
        >
          <v-col cols="12 mb-0 mt-0 pb-0 pt-0">
            <v-toolbar color="teal  lighten-5" dense>
              <div class="font-weight-bold">
                {{
                  getBetTypeText(
                    bettype.BetTypeDetailID,
                    bettype.Permute,
                    bettype.isExtraType,
                    bettype.Percent,
                    bettype.ExtraTypePercent
                  )
                }}
              </div>
              <div class="purple--text ml-4">
                {{
                  getPrizeText(
                    bettype.BetTypeDetailID,
                    bettype.Permute,
                    bettype.isExtraType,
                    bettype.Percent,
                    bettype.ExtraTypePercent
                  )
                }}
              </div>
              <v-spacer></v-spacer>
              <button>ลบ</button>
            </v-toolbar>

            <v-card-text class="mt-0 pt-0 pl-1">
              <v-chip
                class="ma-2"
                close
                :color="bettype.Percent == 0 ? 'red' : 'teal darken-1'"
                text-color="white"
                close-icon="mdi-close-circle"
                v-for="(hp_number, index_number) in bettype.num"
                :key="index_number"
                @click:close="OnHpClose(bettype.BetTypeDetailID, hp_number)"
              >
                {{ hp_number }}
              </v-chip>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

export default {
  name: "Add_Data",
  mixins: [globalFunctionMixin],
  components: {},
  props: ["ReBtHostID", "ReBtHostName", "Sort"],

  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => {
          this.ckLogin();
          this.selectedPrize = 1;
          this.selectedType = 3;
        });
      }
    },
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    bettype_items() {
      return this.$store.getters["bookie/getHpBtTypeById"](this.ReBtHostID);
    },
  },
  methods: {
    submitData() {
      alert("submit");
    },
    changedValue: function(value) {
      //receive the value selected (return an array if is multiple)
      console.log(value);
    },
    OnAddBetType() {
      if (this.BetNumber == "") {
        this.$alert("ต้องใส่เลขที่ต้องการอั้น", "ผิดพลาด", "error").then(() => {
          this.$refs.txtBetNumber.focus();
        });
        return;
      }

      if (/^\d+$/.test(this.BetNumber) == false) {
        this.$alert("เลขอั้นต้องเป็นตัวเลขเท่านั้น", "ผิดพลาด", "error").then(
          () => {
            this.$refs.txtBetNumber.focus();
          }
        );
        return;
      }
      //1=3 ตัวบน ไม่กลับ,2=3 ตัวบน x 6 หาง ไม่รวมโต๊ด,3=3 ตัวบน x 6 หาง x โต๊ด,4=2 ตัว บน+ล่าง,5=3 ตัวโต๊ด, 6=2 ตัวบน , 7=2 ตัวบน พร้อมตัวกลับ, 8=2 ตัวล่าง,9= 2 ตัวล่าง พร้อมตัวกลับ

      const num_size_rule = [3, 3, 3, 2, 3, 2, 2, 2, 2];
      //console.log(this.selectedType);
      if (num_size_rule[this.selectedType - 1] != this.BetNumber.length) {
        if (this.BetNumber.length == 1) {
          this.$confirm("ยืนยันที่ใส่เลขอั้นติด "+this.BetNumber, "ใส่เลขตัวเดียวจะสลับเลขใส่ในหมวดนี้ทั้งหมด", "question")
            .then(() => {
            //   console.log("OK");
              this.checkPrice();
            })
            .catch(() => {
            //   console.log("CANCEL");
              return;
            });
        } else {
          this.$alert(
            "ต้องใส่เลขอั้น " +
              num_size_rule[this.selectedType - 1] +
              " หลักเท่านั้น",
            "ผิดพลาด",
            "error"
          ).then(() => {
            this.$refs.txtBetNumber.focus();
          });
          return;
        }
      }else{
        this.checkPrice();
      }

      
    },

    checkPrice() {
      if (this.selectedType == 3 && this.selectedPrize != 1) {
        if (parseInt(this.Prize1) <= 0 || this.Prize1 == "") {
          this.$alert("ต้องใส่ราคาตรง", "ผิดพลาด", "error").then(() => {
            this.$refs.txtPrize1.focus();
          });
          return;
        }
        if (parseInt(this.Prize2) <= 0 || this.Prize2 == "") {
          this.$alert("ต้องใส่ราคาโต๊ด", "ผิดพลาด", "error").then(() => {
            this.$refs.txtPrize2.focus();
          });
          return;
        }
      }

      if (this.selectedPrize == 2) {
        if (parseInt(this.Prize1) <= 0 || this.Prize1 == "") {
          this.$alert("ต้องใส่ราคาตรง", "ผิดพลาด", "error").then(() => {
            this.$refs.txtPrize1.focus();
          });
          return;
        }
      }

      let dupplicate_number = false;
      //   for (let n1 = 0; n1 < this.hp_type_items.length; n1++) {
      //     for (let n2 = 0; n2 < this.hp_type_items[n1]["number"].length; n2++) {
      //       if (
      //         this.isEqualSwapNumber(
      //           this.BetNumber,
      //           this.hp_type_items[n1]["number"][n2]
      //         )
      //       ) {
      //         this.$alert(
      //           "เลขนี้มีอยู่ในเลขอั้นอยู่แล้ว",
      //           "ผิดพลาด",
      //           "error"
      //         ).then(() => {
      //           this.$refs.txtBetNumber.focus();
      //         });
      //         return;
      //       }
      //     }
      //   }

      let repeat = false;
      //   for (let n = 0; n < this.hp_type_items.length; n++) {
      //     if (
      //       this.selectedType == this.hp_type_items[n]["bet_type"] &&
      //       this.selectedPrize == this.hp_type_items[n]["prize_type"] &&
      //       this.Prize1 == this.hp_type_items[n]["prize_1"] &&
      //       this.Prize2 == this.hp_type_items[n]["prize_2"]
      //     ) {
      //       //add number
      //       this.hp_type_items[n]["number"].push(this.BetNumber);
      //       this.hp_type_items[n]["hp_index"].push(this.hp_index);
      //       this.hp_index++;
      //       repeat = true;
      //       break;
      //     }
      //   }

      //add new hp_type
      //   if (!repeat) {
      //     let NewHP = [];
      //     NewHP["bet_type"] = this.selectedType;
      //     NewHP["prize_type"] = this.selectedPrize;
      //     NewHP["prize_1"] = this.Prize1;
      //     NewHP["prize_2"] = this.Prize2;
      //     NewHP["number"] = [this.BetNumber];
      //     NewHP["hp_index"] = [this.hp_index];
      //     this.hp_type_items.push(NewHP);
      //     this.hp_index++;
      //   }

      this.OnSave();
    },

    OnSave() {
      if (this.Sending == true) return;
      this.Sending = true;
      this.OnCreateHp();
    },

    async OnCreateHp() {
      let credentials = {
        ReBtHostID: this.ReBtHostID,
        PeriodDT: sessionStorage.getItem("period"),
        BetTypeDetailID: 0,
        BetNumber: this.BetNumber,
        Permute: 0,
        isExtraType: 0,
        Percent: 0,
        ExtraTypePercent: 0,
        Sort: this.Sort,
      };

      switch (parseInt(this.selectedType)) {
        case 1: //ตัวบน (ไม่กลับ)
          credentials.BetTypeDetailID = 1;
          credentials.Permute = 0;
          credentials.isExtraType = 0;
          credentials.ExtraTypePercent = 0;
          if (this.selectedPrize == 1) credentials.Percent = 50;
          if (this.selectedPrize == 2) credentials.Percent = this.Prize1 * -1;
          if (this.selectedPrize == 3) credentials.Percent = 0;
          break;
        case 2: //3 ตัวบน + 6 หาง (ไม่รวมโต๊ด)
          credentials.BetTypeDetailID = 1;
          credentials.Permute = 1;
          credentials.isExtraType = 0;
          credentials.ExtraTypePercent = 100;
          if (this.selectedPrize == 1) credentials.Percent = 50;
          if (this.selectedPrize == 2) credentials.Percent = this.Prize1 * -1;
          if (this.selectedPrize == 3) credentials.Percent = 0;
          break;
        case 3: //3 ตัวบน + 6 หาง + โต๊ด
          credentials.BetTypeDetailID = 1;
          credentials.Permute = 1;
          credentials.isExtraType = 1;

          if (this.selectedPrize == 1) {
            credentials.Percent = 50;
            credentials.ExtraTypePercent = 50;
          }
          if (this.selectedPrize == 2) {
            credentials.Percent = this.Prize1 * -1;
            credentials.ExtraTypePercent = this.Prize2 * -1;
          }
          if (this.selectedPrize == 3) {
            credentials.Percent = 0;
            credentials.ExtraTypePercent = 0;
          }
          break;
        case 4: //2 ตัว บน + ล่าง พร้อมตัวกลับ
          credentials.BetTypeDetailID = 3;
          credentials.Permute = 1;
          credentials.isExtraType = 1;

          if (this.selectedPrize == 1) {
            credentials.Percent = 50;
            credentials.ExtraTypePercent = 50;
          }
          if (this.selectedPrize == 2) {
            credentials.Percent = this.Prize1 * -1;
            credentials.ExtraTypePercent = this.Prize1 * -1;
          }
          if (this.selectedPrize == 3) {
            credentials.Percent = 0;
            credentials.ExtraTypePercent = 0;
          }
          break;
        case 5: //3 ตัวโต๊ด
          credentials.BetTypeDetailID = 2;
          credentials.Permute = 0;
          credentials.isExtraType = 0;
          credentials.ExtraTypePercent = 100;
          if (this.selectedPrize == 1) credentials.Percent = 50;
          if (this.selectedPrize == 2) credentials.Percent = this.Prize1 * -1;
          if (this.selectedPrize == 3) credentials.Percent = 0;
          break;
        case 6: //2 ตัวบน (ไม่กลับ)
          credentials.BetTypeDetailID = 3;
          credentials.Permute = 0;
          credentials.isExtraType = 0;
          credentials.ExtraTypePercent = 100;
          if (this.selectedPrize == 1) credentials.Percent = 50;
          if (this.selectedPrize == 2) credentials.Percent = this.Prize1 * -1;
          if (this.selectedPrize == 3) credentials.Percent = 0;
          break;
        case 7: //2 ตัวบน พร้อมตัวกลับ
          credentials.BetTypeDetailID = 3;
          credentials.Permute = 1;
          credentials.isExtraType = 0;
          credentials.ExtraTypePercent = 100;
          if (this.selectedPrize == 1) credentials.Percent = 50;
          if (this.selectedPrize == 2) credentials.Percent = this.Prize1 * -1;
          if (this.selectedPrize == 3) credentials.Percent = 0;
          break;
        case 8: //ตัวล่าง (ไม่กลับ)
          credentials.BetTypeDetailID = 4;
          credentials.Permute = 0;
          credentials.isExtraType = 0;
          credentials.ExtraTypePercent = 100;
          if (this.selectedPrize == 1) credentials.Percent = 50;
          if (this.selectedPrize == 2) credentials.Percent = this.Prize1 * -1;
          if (this.selectedPrize == 3) credentials.Percent = 0;
          break;
        case 9: //2 ตัวล่าง พร้อมตัวกลับ
          credentials.BetTypeDetailID = 4;
          credentials.Permute = 1;
          credentials.isExtraType = 0;
          credentials.ExtraTypePercent = 100;
          if (this.selectedPrize == 1) credentials.Percent = 50;
          if (this.selectedPrize == 2) credentials.Percent = this.Prize1 * -1;
          if (this.selectedPrize == 3) credentials.Percent = 0;
          break;
      }

      await this.$store.dispatch("bookie/hp_add", credentials).then(
        (response) => {
          console.log(response);
          this.ShowSuccess("เพิ่มเลขอั้น " + this.BetNumber + " สำเร็จ");
          this.BetNumber = "";
          this.$refs.txtBetNumber.focus();
          this.Sending = false;
        },
        (error) => {
          console.log(error);
          this.Sending = false;
        }
      );
    },
    async OnHpClose(BetTypeDetailID, BetNumber) {
      let credentials = {
        ReBtHostID: this.ReBtHostID,
        PeriodDT: sessionStorage.getItem("period"),
        BetTypeDetailID: BetTypeDetailID,
        BetNumber: BetNumber,
      };
      await this.$store.dispatch("bookie/hp_del", credentials).then(
        (response) => {
          console.log(response);
          this.ShowDelSuccess("ลบเลขอั้น " + BetNumber + " สำเร็จ");
          this.BetNumber = "";
          this.Sending = false;
        },
        (error) => {
          console.log(error);
          this.Sending = false;
        }
      );
    },
    ShowSuccess(Text) {
      this.snackbar.color = "green";
      this.snackbar.text = Text;
      this.snackbar.show = true;
    },
    ShowDelSuccess(Text) {
      this.snackbar.color = "red";
      this.snackbar.text = Text;
      this.snackbar.show = true;
    },
    getBetTypeText(bet_type, Permute, isExtraType) {
      var bet_type_text = "";
      switch (parseInt(bet_type)) {
        case 1: //3 ตัวบน
          bet_type_text = "3 ตัวบน";
          if (isExtraType == 1) bet_type_text += " โต๊ด";
          if (Permute == 1) bet_type_text += " * 6 หาง";
          if (isExtraType == 0) bet_type_text += " (ไม่รวมโต๊ด)";
          break;
        case 2:
          bet_type_text = "3 ตัวโต๊ด";
          break;
        case 3:
          bet_type_text = "2 ตัว บน";
          if (isExtraType == 1) bet_type_text += " + ล่าง";
          if (Permute == 1) bet_type_text += " พร้อมตัวกลับ";
          if (Permute == 0) bet_type_text += " (ไม่รวมตัวกลับ)";
          break;
        case 4:
          bet_type_text = "2 ตัว ล่าง";
          if (isExtraType == 1) bet_type_text += " + บน";
          if (Permute == 1) bet_type_text += " พร้อมตัวกลับ";
          if (Permute == 0) bet_type_text += " (ไม่รวมตัวกลับ)";
          break;
      }
      return bet_type_text;
    },
    getPrizeText(bet_type, Permute, isExtraType, Percent, ExtraTypePercent) {
      var prize_text = "";
      switch (parseInt(bet_type)) {
        case 1: //3 ตัวบน
          if (Percent == 50) prize_text = " จ่ายครึ่ง";
          if (Percent < 0) {
            //จ่ายบาทละ
            if (isExtraType == 0) {
              prize_text = "จ่ายตรง " + Percent * -1;
            } else {
              prize_text =
                "จ่ายตรง " + Percent * -1 + " โต๊ด " + ExtraTypePercent * -1;
            }
          }
          if (Percent == 0) prize_text = "ปิดรับ";
          return prize_text;
        case 2:
          if (Percent == 0) prize_text = "ปิดรับ";
          if (Percent == 50) prize_text = " จ่ายครึ่ง";
          if (Percent < 0) prize_text = " จ่ายบาทละ " + ExtraTypePercent * -1;
          return prize_text;
        case 3:
          if (Percent == 0) prize_text = "ปิดรับ";
          if (Percent == 50) prize_text = " จ่ายครึ่ง";
          if (Percent < 0) prize_text = " จ่ายบาทละ " + ExtraTypePercent * -1;
          return prize_text;
        case 4:
          if (Percent == 0) prize_text = "ปิดรับ";
          if (Percent == 50) prize_text = " จ่ายครึ่ง";
          if (Percent < 0) prize_text = " จ่ายบาทละ " + ExtraTypePercent * -1;
          return prize_text;
      }
    },
  },
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,

    Sending: false,

    hp_index: 0,
    selectedType: { value: 3 },
    selectedPrize: { value: 1 },
    Prize1: 100,
    Prize2: 30,
    BetNumber: "",
    type_items: [
      { text: "3 ตัวบน + 6 หาง + โต๊ด", value: 3 },
      { text: "2 ตัว บน + ล่าง พร้อมตัวกลับ", value: 4 },
      { text: "3 ตัวบน (ไม่กลับ)", value: 1 },
      { text: "3 ตัวบน + 6 หาง (ไม่รวมโต๊ด)", value: 2 },
      { text: "3 ตัวโต๊ด", value: 5 },
      { text: "2 ตัวบน (ไม่กลับ)", value: 6 },
      { text: "2 ตัวบน พร้อมตัวกลับ", value: 7 },
      { text: "2 ตัวล่าง (ไม่กลับ)", value: 8 },
      { text: "2 ตัวล่าง พร้อมตัวกลับ", value: 9 },
    ],
    prize_item: [
      { text: "จ่ายครึ่ง", value: 1 },
      { text: "บาทละ", value: 2 },
      { text: "ปิดรับ", value: 3 },
    ],

    snackbar: {
      show: false,
      text: "",
      color: "red",
      timeout: 2000,
    },
  }),
};
</script>

<style scoped></style>

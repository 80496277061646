<template>
  <div>
    <v-app-bar dense fixed app color="pink darken-4" dark>
      <v-btn icon to="/menu_data">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>วิเคราะห์ก่อนตัด</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>

    <v-breadcrumbs :items="bc_items" class="grey lighten-3 pa-2" dense>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <MenuTab @onTabChange="onTabChange" />

    <component v-bind:is="component" />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import GenaralTable from "@/components/Data/Export/Export_Decide/Genaral/Datatable";
import HPByBookie from "@/components/Data/Export/Bookie/HPByBookie/Datatable";
import CurrentTable from "@/components/Cus/Line/Current/Datatable";
import AccountTable from "@/components/Cus/Line/Account/Datatable";
import MenuTab from "@/components/Data/Export/Export_Decide/Layout/Menu_Tab";

export default {
  name: "Line_Data",
  mixins: [globalFunctionMixin],
  components: {
    GenaralTable,
    CurrentTable,
    AccountTable,
    MenuTab,
    HPByBookie,
  },
  mounted() {
    this.ckLogin();
    this.bc_items[0].to = { path: "export_begin" };
    this.bc_items[1].text = " วิเคราะห์ก่อนตัด";
    // this.bc_items[1].to = {
    //   path: "agent_data",
    //   query: {
    //     line_id: this.$route.query.line_id,
    //   },
    // };
  },
  data: () => ({
    component: "GenaralTable",

    bc_items: [
      {
        text: "สรุปรวมก่อนส่งออก",
        disabled: false,
      },
      {
        text: "วิเคราะห์ก่อนตัด",
        disabled: true,
      },
    ],
  }),

  methods: {
    onTabChange(id) {
      switch (id) {
        case 1:
          this.component = GenaralTable;
          break;
        case 2:
          this.component = HPByBookie;
          break;
      }
      // alert(id)
    },
  },
};
</script>

<style></style>

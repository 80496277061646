<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :hide-overlay="$vuetify.breakpoint.mdAndDown"
    persistent
    max-width="600px"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" rounded outlined color="pink">
        <v-icon left>
          mdi-human-queue
        </v-icon>
        เจ้ามือ
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="pink darken-4" dense>
        <v-toolbar-title>เลือกเจ้ามือ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <span class="red--text mt-2"
        >** ลำดับใช้เลือกกรณี อัตราจ่าย และเปอร์เซ็นต์ เท่ากัน</span
      >
      <v-card-actions>
        <v-btn outlined color="primary" @click="SelectAll()">
          เลือกทั้งหมด
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="OK()">
          ตกลง
        </v-btn>
      </v-card-actions>

      <div
        style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
        unselectable="on"
        onselectstart="return false;"
        onmousedown="return false;"
      >
        <v-data-table
          :headers="headers"
          :items="bookie_data"
          item-key="id"
          single-select
          show-select
          class="elevation-1"
          hide-default-footer
          disable-pagination
          mobile-breakpoint="0"
          height="h-screen"
          fixed-header
          dense
        >
          <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
          <template v-slot:item="{ item }">
            <!-- @contextmenu="popup_menu($event, item)" -->
            <tr  v-if="item.Status==0">
              <!-- <td><v-checkbox :input-value="props.isSelected" @change="props.select($event)"></v-checkbox></td> -->
              <td>
                <v-checkbox
                  v-model="item.Checked"
                  value="1"
                  @change="CheckedTable($event, item.id)"
                ></v-checkbox>
              </td>
              <td :class="id_column" align="center">
                {{ item.id }}
              </td>
              <td align="end">
                {{ item.Name }}
              </td>
              <td align="center">
                {{
                  item.CloseTime.split(":")[0] +
                    ":" +
                    item.CloseTime.split(":")[1]
                }}
              </td>
              <td align="center">
                {{ item.Priority }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SelectBookie",

  components: {},
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    bookie_data() {
      return this.$store.getters["bookie/Bookies"];
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => {
          this.fetch_table();
        });
      }
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        Method: "GETLIST",
        ListType: "GENARAL_LIST",
      };
      await this.$store.dispatch("bookie/SendBookieData", credentials).then(
        (response) => {
          console.log(response);
          this.selected = [];
          for (var i = 0; i < response.list.length; i++) {
            // console.log(response.genaral_list[i].id);
            this.selected[i] = {
              ID: response.list[i].id,
              Checked: response.list[i].Checked,
            };
          }
          // console.log(this.selected);
        },
        (error) => {
          console.log(error);
        }
      );
    },

    async OK() {
      var SelectedID = "";
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i]["Checked"] == "1") {
          if (SelectedID != "") SelectedID += ",";
          SelectedID += this.selected[i]["ID"];
        }
      }
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        SelectedID: SelectedID,
      };
      await this.$store.dispatch("bookie/set_selected", credentials).then(
        (response) => {
          this.$emit("UpdateHost");
          this.dialog = false;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    SelectAll() {
      for (let i = 0; i < this.$store.getters["bookie/Bookies"].length; i++) {
        this.$store.getters["bookie/Bookies"][i].Checked = "1";
        this.selected[i]["Checked"] = "1";
      }
    },
    CheckedTable(Value, ID) {
      if (Value == null) {
        for (let i = 0; i < this.selected.length; i++) {
          if (this.selected[i]["ID"] == ID) this.selected[i]["Checked"] = "0";
        }
      } else {
        for (let i = 0; i < this.selected.length; i++) {
          if (this.selected[i]["ID"] == ID) this.selected[i]["Checked"] = "1";
        }
      }
      console.log(this.selected);
    },
  },
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,

    selected: [],

    headers: [
      {
        text: "รหัส",
        align: "center",
        width: "25%",
        sortable: false,
      },
      {
        text: "ชื่อ",
        align: "end",
        width: "25%",
        class: "brown lighten-5",
        sortable: false,
      },
      {
        text: "เวลาปิด",
        align: "center",
        width: "25%",
        class: "grey lighten-4 white-space: normal",
        sortable: false,
      },
      {
        text: "ลำดับ",
        align: "center",
        width: "25%",
        class: "grey lighten-2",
        sortable: false,
      },
    ],

    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  /* background-color: #ffffff !important;
  color: rgb(2, 2, 2); */
  /* text-align: center !important;  
    padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 3%;
  left: 0;
}
</style>
